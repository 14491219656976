import { SwipeableDrawer } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";
import { ReactNode, useState } from "react";
import {
  ContentContainer,
  OpenDrawerButton
} from "../WidgetDrawer/WidgetDrawer.styles";

interface AdaptiveQuizDrawerComponentProps {
  content: ReactNode;
}

const AdaptiveQuizDrawerComponent = ( { content }: AdaptiveQuizDrawerComponentProps) => {

  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <OpenDrawerButton
        className={ !isOpen ? "visible" : "" }
        accent={ "red" }
        icon={ "arrow_widget_open" }
        top={ "5.5rem" }
        onClick={ () => { setIsOpen(true) } }
      />
      <SwipeableDrawer
        open={ isOpen }
        anchor={ "right" }
        onClose={ () => { setIsOpen(false) } }
        onOpen={ () => { setIsOpen(true) } }
        PaperProps= { {
          sx: {
            background: "transparent",
            boxShadow:"none",
            height: "calc(100% - 6rem)",
            overflowY:"visible",
            top:  isMobile ? "8.8rem" : " 5rem",
            [theme.breakpoints.down("sm")]:{
              maxWidth: "calc(100% - 40px)",
              right: "20px"
            },

            ".MuiPaper-root":{
              overflowX: "hidden",
              overflowY: "auto",

              "&:hover": {
                "&::-webkit-scrollbar-thumb":{
                  backgroundColor: theme.customColors.backgroundSecondary
                }
              },

              "::-webkit-scrollbar": {
                width: "6px"

              },

              "::-webkit-scrollbar-thumb":{
                backgroundColor: "transparent",
                borderRadius: "8px"
              }
            }

          }
        } }
        slotProps={ { backdrop: { classes: { root: "backdrop-widget" } } } }
        sx= { {
          position: "relative",
          zIndex: "1400",
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0,0,0,0.7)"
          }
        } }
      >
        <ContentContainer>
          {
            isOpen ? <> { content } </> : null
          }
        </ContentContainer>
      </SwipeableDrawer>
    </>
  );
};

export default AdaptiveQuizDrawerComponent;
