import { styled } from "@mui/system";

export const DotsWrapper = styled("div")(() => ({
  alignItems: "center",
  display: "flex",
  gap: "8px",
  height: "20px",
  justifyContent: "center"
}));

interface DotProps {
  delay?: string;
}

export const Dot = styled("div")<DotProps>(({ delay }) => ({
  "@keyframes animationDots": {
    "0%, 80%, 100%": {
      transform: "scale(0)"
    },
    "40%": {
      transform: "scale(1)"
    }
  },
  animation: "animationDots 1.4s ease-in-out infinite",
  animationDelay: delay,
  backgroundColor: "#fff",
  borderRadius: "50%",
  height: "10px",
  width: "10px"
}));
