import { useGSAP } from "@gsap/react";
import { Box, IconButton, TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { gsap } from "gsap";
import { useEffect, useRef, useState } from "react";
import {
  AdaptiveQuizContainer,
  ChatBox, ChatInput,
  ChatMessagesContainer,
  ChatMessagesParentContainer
} from "./AdaptiveQuizChat.styles";
import { QuizStatusProps } from "./AdaptiveQuizChat.types";
import AdaptiveQuizChatAnimatedAvatarComponent from "./AdaptiveQuizChatAnimatedAvatar.component";
import AdaptiveQuizChatBubbleComponent from "./AdaptiveQuizChatBubble.component";
import AdaptiveQuizChatSidebarComponent from "./AdaptiveQuizChatSidebar.component";
import AdaptiveQuizDrawerComponent from "./AdaptiveQuizDrawer.component";
import AdaptiveQuizResultComponent from "./AdaptiveQuizResult.component";
import { useAdaptiveQuizMutation, useAdaptiveQuizResultsQuery } from "../../queries/adaptiveQuiz";



export interface AdaptiveQuizChatMessage {
  sender: "ai" | "user",
  message: string
}

const CHAT_PADDING_Y = 30;
const CHAT_PADDING_X = 10;
const ANIMATION_DURATION = 0.5;

export const TOPICS = [
  "Natural Language Processing (NLP)",
  "Big Data e Intelligenza Artificiale",
  "Deep Learning"
];

const fileName = `valutazioni_${crypto.randomUUID()}.json`;

const initialQuizStatusState: QuizStatusProps = {
  "current_level": "",
  "next_level": "",
  "current_topic": "",
  "question": "",
  "correct_answer": "",
  "topics_file_key": "prompt-catalog/project-1/claude-sonnet-3-5/version-1/argomenti/level_topics.json",
  "learner_answer": "",
  "valutazioni_file_name": fileName,
  "current_macro_topic_index": 0,
  "macro_topics": [
    "NLP",
    "big_data",
    "deep_learning"
  ],
  "questions_by_level": {
    "max_correct": 2,
    "max_partial": 1,
    "max_wrong": 1,
    "remaining_correct": 2,
    "remaining_partial": 1,
    "remaining_wrong": 1
  },
  "progress": 0,
  "is_end_assessment": false
};

const AdaptiveQuizChat = () => {

  const theme = useTheme();
  const [chatMessages, setChatMessages] = useState<AdaptiveQuizChatMessage[]>([]);
  const [userAnswer, setUserAnswer] = useState("");
  const firstMessageLoaded = useRef<boolean>(false);
  const [isFetchingAiMessage, setIsFetchingAiMessage] = useState(false);
  const [quizStatus, setQuizStatus] = useState<QuizStatusProps>(initialQuizStatusState);
  const [messageError, setMessageError] = useState("");
  const [inputDisabled, setInputDisabled] = useState(true);

  const inputRef = useRef<HTMLInputElement>(null);

  const { mutateAsync: mutateQuestionQuiz } = useAdaptiveQuizMutation();
  const { data: quizResults } = useAdaptiveQuizResultsQuery({
    enabled: quizStatus.is_end_assessment,
    valutazioniFileName: fileName
  });

  const animationRefs = {
    adaptiveQuizContainer: useRef<HTMLDivElement>(null),
    chatFirstMessage: useRef<HTMLDivElement>(),
    chatLoadingAvatar: useRef<HTMLDivElement>(null),
    chatMessagesContainer: useRef<HTMLDivElement>(null),
    evaluationContainer: useRef<HTMLDivElement>(null),
    resultsContainer: useRef<HTMLDivElement>(null),
    resultsContainerAvatar: useRef<HTMLDivElement>(null)
  };

  const handleMutation = async (quizStatus: QuizStatusProps): Promise<void> => {
    setIsFetchingAiMessage(true);
    setInputDisabled(true);
    try {
      let data = await mutateQuestionQuiz({ ...quizStatus, valutazioni_file_name: fileName });
      setQuizStatus(data);
      const question = data.question;
      setChatMessages( (chatMessages) => [...chatMessages, { message: question, sender: "ai" }]);
      if (data.is_end_assessment){
        goToEvaluation();
      }
    } catch (error) {
      setMessageError(`${error}`);
    }
    finally {
      setInputDisabled(false);
      setIsFetchingAiMessage(false);
    }
  };
  const goToEvaluation = ()=>{
    setContainerVisibility({
      chat: false,
      evaluation: true,
      result: false
    });
  };

  const goToResults = ()=>{
    setContainerVisibility({
      chat: false,
      evaluation: false,
      result: true
    });
  };

  useEffect(() => {
    quizResults && goToResults();
  }, [quizResults]);

  const getFirstAiMessage = ()=>{
    firstMessageLoaded.current = true;
    handleMutation(quizStatus);
  };

  useEffect(()=>{
    if (!firstMessageLoaded.current){
      getFirstAiMessage();
    }
  },[firstMessageLoaded.current]);

  const [containerVisibility, setContainerVisibility] = useState({
    chat: true,
    evaluation: false,
    result: false
  });

  const handleError = ()=>{
    if (!quizStatus.learner_answer){
      getFirstAiMessage();
    }else{
      submitAnswer();
    }
  };

  const submitAnswer = async () => {
    if(!userAnswer) return;
    //Aggiungo la risposta all'array
    setChatMessages((currentList) => [
      ...currentList,
      { message: userAnswer, sender: "user" }
    ]);

    handleMutation({
      ...quizStatus,
      learner_answer: userAnswer
    });

    //Resetto il campo
    setUserAnswer("");
  };


  // animateChatLoadingAvatar
  useGSAP(()=>{
    let tl = gsap.timeline({ paused: true });
    const avatar = animationRefs.chatLoadingAvatar.current;
    const firstMessageBox = animationRefs.chatMessagesContainer.current?.firstElementChild!.firstElementChild;
    const firstMessageBoxAvatar = firstMessageBox?.firstChild as HTMLDivElement;

    if(avatar && firstMessageBox && firstMessageBoxAvatar){

      const boxSize = firstMessageBoxAvatar.getBoundingClientRect();
      const messageRowHeight = firstMessageBox.getBoundingClientRect().height;

      if (firstMessageBox && chatMessages.length || messageError){
        tl.to(avatar, { // spostamento avatar in basso a sinistra
          duration: ANIMATION_DURATION,
          bottom: `${messageRowHeight / 2 + CHAT_PADDING_Y}px`,
          left: `${boxSize.width / 2 + CHAT_PADDING_X}px`,
          height: boxSize.height,
          width: boxSize.width,
          ease: "power4.out",
          onComplete: () => {
            avatar.remove();
          }
        }).to(avatar.firstChild!.firstChild!, { // animazione border e svg dell'avatar
          duration: ANIMATION_DURATION,
          width: `${boxSize.width}px`,
          height: `${boxSize.height}px`,
          attr: { viewBox: `0 0 ${boxSize.width} ${boxSize.height}` },
          ease: "power4.out"
        }, "<").to(avatar.firstChild!.firstChild!.firstChild, { // animazione circle nell'svg dell'avatar
          duration: ANIMATION_DURATION,
          attr: { cx: boxSize.width / 2, cy: boxSize.height / 2, r: boxSize.height / 2, "stroke-width": 1 },
          ease: "power4.out"
        }, "<");
        tl.play();
      }
    }
  },[chatMessages?.length, messageError]);

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputDisabled]);

  useEffect(() => {
    animationRefs.chatMessagesContainer.current?.scrollTo({
      top: animationRefs.chatMessagesContainer.current?.scrollHeight, // Posizionarsi alla fine
      behavior: "smooth"
    });
  }, [isFetchingAiMessage, animationRefs.chatMessagesContainer.current]);

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && !e.altKey && !e.shiftKey) {
      submitAnswer();
      e.preventDefault();
    }
  };

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (

    <Box sx={ {
      position: "relative",
      overflow: "hidden"
    } }>

      { /*CHAT*/ }
      { containerVisibility.chat && (
        <AdaptiveQuizContainer ref={ animationRefs.adaptiveQuizContainer }>

          { isMobile ? (
            <AdaptiveQuizDrawerComponent
              content={ <AdaptiveQuizChatSidebarComponent
                currentIndex={ quizStatus.current_macro_topic_index }
                progress={ quizStatus.progress }
                topics={ TOPICS } />
              }
            />
          ) : (
            <AdaptiveQuizChatSidebarComponent currentIndex={ quizStatus.current_macro_topic_index }
              progress={ quizStatus.progress }
              topics={ TOPICS }
            />
          ) }
          <ChatBox>
            <ChatMessagesParentContainer>
              <AdaptiveQuizChatAnimatedAvatarComponent
                loading={ !chatMessages.length }
                ref={ animationRefs.chatLoadingAvatar }
                sx={ { overflow: "hidden",
                  position: "absolute",
                  marginInline: "auto",
                  bottom: "50%",
                  left: "50%",
                  transform: "translate(-50%, 50%)"
                } }/>
              { !!chatMessages.length && <ChatMessagesContainer ref={ animationRefs.chatMessagesContainer } gap={ "15px" }>
                <Box sx={ {
                  alignItems: "end",
                  display: "flex",
                  flexDirection: "column",
                  padding: `${CHAT_PADDING_Y}px ${CHAT_PADDING_X}px`,
                  minHeight: "100%",
                  justifyContent: "end" } }>
                  { chatMessages.map((data, index) => <AdaptiveQuizChatBubbleComponent
                    key={ `message_${index}_${data.sender}` } highlight={ index >= chatMessages.length - 2 }
                    { ...data } />) }
                  { (isFetchingAiMessage || messageError) &&
                    <AdaptiveQuizChatBubbleComponent key={ "message_loading" } sender={ "ai" } highlight={ true } message={ messageError } isError={ !!messageError } errorHandler={ handleError } /> }
                </Box>
              </ChatMessagesContainer> }
            </ChatMessagesParentContainer>
            <Box sx={ {
              width: "100%",
              padding: "0 50px"
            } }>
              <ChatInput>
                <Box sx={ {
                  flexGrow: 1
                } }>
                  <TextField
                    variant={ "standard" }
                    placeholder={ "Inserisci qui la tua risposta" }
                    size={ "small" }
                    disabled={ inputDisabled }
                    value={ userAnswer }
                    multiline={ true }
                    sx={ { padding: 0, width: "100%" } }
                    onChange={ (e) => setUserAnswer(e.target.value) }
                    onKeyDown={ (event) => handleInputKeyDown(event) }
                    inputRef = { inputRef }
                    InputProps={ { style: {
                      fontSize: "0.9rem"
                    },
                    disableUnderline: true
                    } }
                  />
                </Box>
                <IconButton onClick={ submitAnswer } disabled={ !userAnswer }>
                  <svg style={ {
                    display: "inline-block",
                    stroke: (userAnswer ? theme.customColors.systemPrimary01 : theme.customColors.systemDisabled),
                    fill: "transparent",
                    width: "1.5rem",
                    height: "1.5rem"
                  } }>
                    <path
                      d="M1.09339 18.8458C0.617785 20.2173 2.04887 21.4731 3.34761 20.824L20.1048 12.4479C21.2984 11.8514 21.2984 10.1486 20.1048 9.55206L3.34761 1.17604C2.04887 0.526866 0.617786 1.78271 1.09339 3.15423L3.63023 10.4698C3.74934 10.8132 3.74933 11.1868 3.63023 11.5302L2.99602 13.3591L2.67891 14.2736L2.36181 15.188"
                      fill="transparent"></path>
                  </svg>
                </IconButton>
              </ChatInput>
            </Box>
          </ChatBox>
        </AdaptiveQuizContainer>
      ) }

      { /* RESULT */ }
      { (containerVisibility.result || containerVisibility.evaluation) && <AdaptiveQuizResultComponent result={ containerVisibility.result } evaluation={ containerVisibility.evaluation } quizResults={ quizResults } /> }
    </Box>
  );
};

export default AdaptiveQuizChat;
