import { useGSAP } from "@gsap/react";
import { Box, SxProps, Theme } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { gsap } from "gsap";
import { forwardRef, useEffect, useRef } from "react";
import avatarIcon from "../../assets/avatar.svg";

type AdaptiveQuizChatAnimatedAvatarParams = {
  loading: boolean,
  sx?: SxProps<Theme>,
  height?: number
}

const ANIMATION_DURATION = 7;

const AdaptiveQuizChatAnimatedAvatar = forwardRef(({ loading, sx, height = 150 }: AdaptiveQuizChatAnimatedAvatarParams, chatLoadingAvatar) => {

  const theme = useTheme();
  const radius = height/2;
  const circumference = (2 * Math.PI * radius);

  const animationTlRef = useRef<GSAPTimeline | null>(null);

  const animatedBorderRef = useRef<SVGCircleElement>(null);

  const animateChatLoadingAvatarBorder = () => {
    if(animatedBorderRef.current){
      animationTlRef.current = gsap.timeline({ paused: true });
      animationTlRef.current.to(animatedBorderRef.current, {
        strokeDashoffset: -circumference,
        duration: ANIMATION_DURATION,
        delay: 0.5,
        repeat: -1,
        ease: "linear"
      });
    }
  };

  useGSAP(()=>{
    animateChatLoadingAvatarBorder();
  },[]);

  useEffect(()=>{
    if (loading) {
      animationTlRef.current?.play();
    }else {
      animationTlRef.current?.pause();
      gsap.set(animatedBorderRef.current, { strokeDashoffset: 0 });
    }
  },[loading]);



  return (
    <Box
      ref={ chatLoadingAvatar }
      sx={ {
        width: `${height}px`,
        height: `${height}px`,
        borderRadius: "50%",
        boxShadow: `0px 0px 5px 0px ${theme.customColors.accentVariantE}`,
        ...sx
      } }
    >
      <Box sx={ { position: "relative", width: "100%", height: "100%" } }>
        <svg
          width={ `${height}px` }
          height={ `${height}px` }
          viewBox={ `0 0 ${height} ${height}` }
          overflow="visible"
          style={ { position: "absolute", top: 0, left: 0, right: 0, bottom: 0 } }
        >
          <circle
            ref={ animatedBorderRef }
            cx={ `${height / 2}` }
            cy={ `${height / 2}` }
            r={ radius }
            fill={ "none" }
            stroke={ theme.customColors.accentVariantE }
            strokeWidth={ height > 100 ? 4 : 2 }
            strokeDasharray={ circumference }
            strokeDashoffset={ circumference }
            transform={ `rotate(-90 ${height / 2} ${height / 2})` }
          />
        </svg>

        <img src={ avatarIcon } alt={ "Avatar" } style={ { width: "100%", height: "100%" } }/>
      </Box>
    </Box>
  );
});

AdaptiveQuizChatAnimatedAvatar.displayName = "AdaptiveQuizChatAnimatedAvatar";

export default AdaptiveQuizChatAnimatedAvatar;
