import { Avatar, Box, styled, Typography } from "@mui/material";

export const StyledAvatar = styled(Avatar)(({ color }) => ({
  borderRadius: "50%",
  boxShadow: `0px 0px 7px 0px ${color}`,
  height: "100%",
  width: "100%"
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.systemSecondary01,
  fontSize: "20px",
  fontWeight: "bold",
  flexGrow: 0
}));

export const ChatBubble = styled(Box)(() => ({
  alignItems: "center",
  display: "flex",
  width: "90%",
  margin: "20px 0",
  overflow: "visible",
  gap: "10px",
  "&:last-child": {
    margin: 0
  },
  "&.ai-message": {
    marginRight: "auto",
    flexDirection: "row"
  },
  "&.user-message": {
    marginLeft: "auto",
    flexDirection: "row-reverse"
  }

}));

export const AdaptiveQuizContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  maxHeight: "700px",
  height: "100%",
  padding: "10px",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0)
  }
}));

export const ChatBox = styled(Box)(({ theme }) => ({
  display: "flex",
  position: "relative",
  flexDirection: "column",
  alignItems: "end",
  justifyContent: "end",
  flexGrow: 1,
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),
  height: "500px",
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0)
  }
}));

export const ChatMessagesParentContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  position: "relative",
  flexGrow: 1,
  overflowY: "auto",
  [theme.breakpoints.down("sm")]: {
    height: "100%"
  }
}));

export const ChatMessagesContainer = styled(Box)(({ theme }) => ({
  display: "block",
  opacity: 1,
  overflowY: "auto",
  width: "100%",
  height: "100%",
  "&::-webkit-scrollbar": {
    background: "transparent",
    opacity: 0.3,
    width: "7px"
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: 4,
    background: theme.customColors.border
  }
}));

export const ChatInput = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "10px 20px 10px 30px",
  borderRadius: "30px",
  backgroundColor: `${theme.customColors.inputBackground}`,
  border: `1px solid ${theme.customColors.inputBorder}`,
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.25)",
  [theme.breakpoints.down("sm")]: {
    padding: "10px 15px 10px 25px"
  }
}));

export const AdaptiveQuizChatSidebarBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flexShrink: 0,
  borderRight: "1px solid gray",
  paddingRight: "50px",
  paddingTop: "50px",
  paddingBottom: "50px",
  [theme.breakpoints.down("sm")]: {
    borderRight: "none"
  },
  [theme.breakpoints.up("sm")]:{
    maxWidth: "30%"
  }
}));

export const ChatMessage = styled(Box)<{ $isAi: boolean }>(({ theme, $isAi }) => ({
  backgroundColor: theme.customColors.backgroundPrimary,
  borderRadius: theme.spacing(1),
  boxShadow: `inset ${$isAi ? "1px" : "-1px"} 0px 0px 0px
    ${$isAi ? theme.customColors.systemSecondary01 : theme.customColors.systemPrimary02}, 0 0 3px 1px rgb(18,18,18)`,
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(1)
}));

export const ResultMessageBox = styled(ChatMessage)(({ theme }) => ({
  padding: theme.spacing(2),
  [theme.breakpoints.down("lg")]: {
    marginLeft: "130px"
  }
}));
