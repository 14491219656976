import type { UserData } from "../types";

export function getUserAvatar(profileImage: string | undefined | null, userData: UserData | null) {
  return {
    initials: `${userData?.name?.replaceAll(" ", "")} ${userData?.surname}`,
    src:  profileImage || undefined
  };
}

export function stringAvatar(name: string) {
  return {
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
  };
}
