import { Dot, DotsWrapper } from "./DotsLoader.styles";

const DotsLoader = () => {
  return (
    <DotsWrapper>
      <Dot delay="0s" />
      <Dot delay="0.2s" />
      <Dot delay="0.4s" />
    </DotsWrapper>
  );
};

export default DotsLoader;
