import { QueryKey, useMutation, useQuery } from "@tanstack/react-query";
import { QuizStatusProps } from "../components/AdaptiveQuiz/AdaptiveQuizChat.types";
import { fetcherFactory } from "../services";
import { getEnvironmentVariables } from "../utils/general";

export function useAdaptiveQuizMutation() {
  const fetcher = fetcherFactory();
  const { adaptiveQuizAiUri } = getEnvironmentVariables();

  return useMutation({
    mutationFn: (quizStatus: QuizStatusProps) => fetcher(
      `${adaptiveQuizAiUri}/get_question`,
      {
        headers:{
          "content-type":"application/json"
        },
        body: JSON.stringify({
          "payload": {
            "current_level": quizStatus.current_level,
            "next_level": quizStatus.next_level,
            "current_topic": quizStatus.current_topic,
            "question": quizStatus.question,
            "correct_answer": quizStatus.correct_answer,
            "topics_file_key": quizStatus.topics_file_key,
            "learner_answer": quizStatus.learner_answer || "",
            "valutazioni_file_name": `${quizStatus.valutazioni_file_name}`,
            "current_macro_topic_index": quizStatus.current_macro_topic_index,
            "macro_topics": quizStatus.macro_topics,
            "questions_by_level": {
              ...quizStatus.questions_by_level
            },
            "progress": quizStatus.progress
          }
        }),
        method: "POST"
      }).then((res) => res.json() )
      .catch((error) => {
        throw error;
      })
  });
}

export function useAdaptiveQuizResultsQuery({ enabled, valutazioniFileName, queryKey = [] }: AdaptiveQuizResultsProps) {
  const fetcher = fetcherFactory();
  const { adaptiveQuizAiResultUri } = getEnvironmentVariables();
  const uri = `${adaptiveQuizAiResultUri}/final_evaluation`;
  const queryString = `?valutazioni_file_name=${valutazioniFileName}`;

  return useQuery<
    unknown,
    unknown,
    AdaptiveQuizResult,
    QueryKey
  >({
    enabled: Boolean(enabled),
    queryFn: () => fetcher(`${uri}${queryString}`, {
      body: null,
      headers: {
        "Content-Type": "application/json"
      },
      method: "GET"
    })
      .then((res) => {
        return res.json();
      })
      .then((data: AdaptiveQuizResult) => {
        return data;
      })
      .catch((error) => {
        throw error;
      }),
    queryKey
  });
}
