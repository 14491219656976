import { Box, Typography } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import { TOPICS } from "./AdaptiveQuizChat.component";
import { StyledAvatar } from "./AdaptiveQuizChat.styles";
import avatarIcon from "../../assets/avatar.svg";
import { useAuthStore } from "../../stores";
import { Button } from "../Button/Button.component";
import { Icon } from "../index";

interface AdaptiveQuizWelcomeProps {
  goToChat: () => void
}

const AdaptiveQuizWelcomeComponent = ({ goToChat }: AdaptiveQuizWelcomeProps) => {

  const theme = useTheme();
  const userData = useAuthStore(state => state.userData);

  return (
    <Box sx={ {
      display: "flex",
      flexDirection: "column",
      maxWidth: "md",
      width: "100%",
      background: theme.linearGradients.gradientB,
      borderRadius: "10px",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: 5,
      paddingX: 10,
      paddingY: 3,
      [theme.breakpoints.down("sm")]: {
        padding: 3
      }
    } }>
      <Box sx={ {
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
        alignItems: "center",
        marginBottom: 5
      } } gap={ "30px" }>
        <Box style={ { position: "relative",
          width: "100px", height: "100px", borderRadius: 999, flexShrink: 0, border: `2px solid ${theme.customColors.systemSecondary01}`
        } }>
          <StyledAvatar
            color={ theme.customColors.systemSecondary01 }
            src={ avatarIcon }
          />
        </Box>
        <Typography sx={ {
          fontSize: "32px",
          fontWeight: "bold",
          [theme.breakpoints.down("sm")]: {
            fontSize: "28px"
          }
        } }>Ciao { userData?.name || "Utente" }!</Typography>
      </Box>

      <Typography sx={ {
        [theme.breakpoints.down("sm")]: {
          textAlign: "left"
        }
      } }>
        <b>Io sono Phoenix, il tuo Digital Learning Coach e sono qui per guidarti.</b><br/>
        Benvenut* in questo quiz sull&apos;intelligenza artificiale, ti porrò delle <b>domande</b>
        generate sulla base delle tue <b>risposte</b> per capire quali attività didattiche hai
        bisogno di potenziare per <b>approfondire le tue competenze</b>.<br/>Sei pronto?
      </Typography>

      <Box sx={ {
        marginTop: 5,
        display: "flex",
        flexDirection: "column"
      } } gap={ "15px" }>
        <Typography sx={ { color: theme.customColors.textPrimary, fontWeight: "bold" } }>Argomenti</Typography>
        {
          TOPICS.map((topic, index) => <Box key={ `topic_welcome_${index}` } sx={ {
            display: "flex",
            flexDirection: "column"
          } } gap={ "10px" }>
            <Box sx={ {
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            } } gap={ "10px" }>
              <Icon
                color={ alpha(theme.customColors.textPrimary, 0.6) }
                icon={ "Icons_modulo-completato" }
                size="1.25rem"
              />
              <Typography>{ topic }</Typography>
            </Box>
          </Box>
          )
        }
      </Box>

      <Box sx={ {
        alignSelf: "center",
        marginTop: 5
      } }>
        <Button variant={ "contained" } onClick={ goToChat }>Inzia il quiz</Button>
      </Box>
    </Box>
  );
};

export default AdaptiveQuizWelcomeComponent;
