import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Divider,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Icon } from "../../components";
import { NAVBAR_ITEMS } from "../../consts";
import { useScrollDirection } from "../../hooks";
import {
  useAuthStore,
  useNavbarStore
} from "../../stores";

/** NOTE:
 * issue with the props types for BottomNavigationAction with styled:
 * the href prop isn't deetected properly
 * cfr. @mui/material/ButtonBase/ButtonBase.d.ts: "This component has an additional
 * overload if the `href` prop is set which can make extension quite tricky"
 */
// const StyledBottomNavigationAction = styled(BottomNavigationAction)(() => ({
//   textTransform: "capitalize"
// }));


export function Navbar() {
  const theme = useTheme();
  const { t } = useTranslation();
  const setNavbarItem = useNavbarStore(state => state.setNavbarItem);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const smartConfiguration = useAuthStore(state => state.smartConfiguration);
  const enableCommunityFlag = useAuthStore(state => state.corporateInfo?.enable_community_flag);
  const navbarItems = getNavbarItems({ size: "20px" });
  const scrollDirection = useScrollDirection();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const getActiveItem = (link: string) => {
    return pathname.startsWith(link);
  };
  

  return (
    <Box
      component="nav"
      className={ !isMobile && scrollDirection && scrollDirection === "up" ? "is-sticky" : "is-static" }
      position={ !isMobile && scrollDirection && scrollDirection === "up" ? "sticky" : "static" }
      top={ 0 }
      id="navbar"
      zIndex={
        isMobile || (scrollDirection && scrollDirection === "up") ?  theme.zIndex.drawer : 1 }
      sx={ {
        animation:`effectExit 400ms ${theme.transitions.easing.easeInOut}` ,

        "&#navbar-clone": {
          "& .isActiveNavigationItem": {
            marginBottom: "20px",
            color: "#fff",

            "& svg *": {
              fill: "#fff"
            },
            
            "& .MuiBottomNavigationAction-label": {
              color: "#fff",
              fontWeight: 400
            }
          },
          "& .isActiveNavigationItem:after, .active-tutorial:after": {
            content: "none"
          },
          "& .active-tutorial": {
            color: "#A6FF03",
            [theme.breakpoints.down("md")]: {
              marginBottom: "0"
            },

            "& svg": {
              [theme.breakpoints.down("md")]: {
                translate: "0 -20px"
              }
            },

            "& svg *": {
              fill: "#A6FF03"
            },

            "& .MuiBottomNavigationAction-label": {
              color: "#A6FF03",
              fontWeight: 700,
              [theme.breakpoints.down("md")]: {
                translate: "0 -20px"
              }
            }
          }
        },

        "&.is-sticky":{
          animation:`effectEnter 300ms ${theme.transitions.easing.easeInOut}`
        },

        "@keyframes effectExit": {
          " 0%": {

            transform: "translateY(0)"
          },
          "100%":{
            transform: "translateY(-200%)"
          }
        },

        "@keyframes effectEnter": {
          " 0%": {
            transform: "translateY(-30%)"
          },
          "100%":{
            transform: "translateY(0)"
          }
        }
      } }
    >
      <Divider />
      <BottomNavigation
        component={ "ul" }
        showLabels
        sx={ {
          borderBottom:`1px solid ${theme.customColors.border}`,
          borderTop:`1px solid ${theme.customColors.border}`,
          gap:theme.spacing(8),
          marginBlock: "0",
          minHeight:"92px",
          padding:"0 16px",

          ".MuiBottomNavigationAction-root": {
            flex:0,
            gap:"5px",
            justifyContent:"flex-end",
            margin: "0 ",
            maxWidth: "unset",
            minWidth:"unset",
            padding: 0,
            position:"relative",
            width:"fit-content",

            "&:after":{
              height:"3.33px",
              width:"100%",

              background:theme.customColors.accentVariantA,
              borderRadius:"2px",
              boxShadow:` 0px 0px 15.9834px ${theme.customColors.accentVariantA}`,
              content:"''",
              margin:"10px 0 0",
              opacity:0,
              transition:"all .3s linear"
            },

            "&.isActiveNavigationItem" : {
              color: `${theme.customColors.accentVariantA} !important`,

              ".MuiBottomNavigationAction-label": {
                color:theme.customColors.accentVariantA,
                fontWeight:"700"
              },
              "svg path": {
                fill: `${theme.customColors.accentVariantA}`
              },

              "&:after":{
                opacity: isMobile ? 0 : 1
              }
            },

            ".MuiBottomNavigationAction-label": {
              color:theme.customColors.textPrimary,
              fontSize:"1rem",
              fontWeight:"700", // Change for Accessibity report with AB
              textTransform: "capitalize",
              transition:"color .3s linear",
              whiteSpace:"nowrap"
            },

            "svg path":{
              fill: theme.customColors.systemPrimary01,
              transition:"color .3s linear"
            }
          },

          [theme.breakpoints.down("sm")]:{
            bottom:"0",
            //gap:"1.5rem",
            gap:"10px",
            justifyContent: "space-around",
            left:"0",
            //height:"auto",
            minHeight:"60px",
            padding:"0 20px",
            position:"fixed",
            right:"0",
            width: "100%",

            //minHeight:"unset",
            //overflowX:"scroll",
            //padding:"0 28px",
            zIndex:" 999999",

            ".MuiBottomNavigationAction-root" : {
              //fontSize: "0.75rem",
              //padding:"9px 0",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              lineHeight: "20px",
              marginBottom: "5px",
              padding: "0px 1px",

              "&:after" : {
                background: "unset",
                margin: "0"
              },

              "&.isActiveNavigationItem" : {
                borderBottom: 0,
                borderTop:`0.15625rem solid ${theme.customColors.accentVariantA}`,

                ".MuiBottomNavigationAction-label": {
                  color:theme.customColors.accentVariantA,
                  fontWeight:"500"
                }
              },

              ".MuiBottomNavigationAction-label": {
                color: theme.customColors.textPrimary,
                fontSize:"12px",
                fontWeight:400,
                lineHeight:"14px",
                textTransform: "capitalize",
                transition:"color .3s linear",
                whiteSpace:"nowrap"
              }
            }
          }
        } }
      >
        {
          navbarItems.map(({ href, icon, item, label }) => {
            // const isActive = location.pathname === href || ( location.pathname.includes(href) && href !== "/") ;
            const classes = (getActiveItem(href)) ? "isActiveNavigationItem" : undefined;
            return (
              <BottomNavigationAction
                disableRipple
                key={ `navigation-item--${label}` }

                className={ classes }
                href={ href }
                icon={ icon }
                label={ t(label) }
                LinkComponent={ "li" }
                onClick={ () => { setNavbarItem(item); navigate(href) } }
                onKeyUp={ (e) => { if( e.key === "Enter" ) { setNavbarItem(item); navigate(href) } } }
                id={ label }
              />
            );
          })
        }
      </BottomNavigation>
      <Divider />
    </Box>
  );

  function getNavbarItems({ size }: { size: string }) {
    let items = [
      {
        href: "/per-te",
        icon: <Icon icon={ "Icons_Per-te" } size={ size } />,
        item: NAVBAR_ITEMS.FOR_YOU,
        label: "for_you"
      },
      {
        href: "/esplora",
        icon:<Icon icon={ "esplora" } size={ size } />,
        item: NAVBAR_ITEMS.EXPLORE,
        label: "explore"
      },
      {
        href: "/bookmarks",
        icon: <Icon icon={ "Icons_Preferiti" } size={ size } />,
        item: NAVBAR_ITEMS.BOOKMARKS,
        label: "bookmarks"
      },
      {
        href: "/community",
        icon: <Icon icon={ "community" } size={ size } />,
        item: NAVBAR_ITEMS.COMMUNITY,
        label: "community"
      },
      {
        href: "/agenda",
        icon: <Icon icon={ "agenda" } size={ size }/>,
        item: NAVBAR_ITEMS.AGENDA,
        label: "agenda"
      }
    ];

    // eslint-disable-next-line no-constant-condition
    if (smartConfiguration?.visForyou === false) {
      items = items.filter((item) => item.item !== NAVBAR_ITEMS.FOR_YOU);
    }
    if (smartConfiguration?.funcFavourites === false) {
      items = items.filter((item) => item.item !== NAVBAR_ITEMS.BOOKMARKS);
    }
    if(
      enableCommunityFlag === false
      || (enableCommunityFlag === true && smartConfiguration?.funcCommunity === false)
    ) {
      items = items.filter((item) => item.item !== NAVBAR_ITEMS.COMMUNITY);
    }
    if (smartConfiguration?.widgetCalendar === false) {
      items = items.filter((item) => item.item !== NAVBAR_ITEMS.AGENDA);
    }

    return items;
  }
}
