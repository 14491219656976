import { Stack } from "@mui/material";
import { Fragment, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import {
  Outlet,
  useLoaderData,
  useLocation,
  useNavigate,
  useSearchParams
} from "react-router-dom";
// import IntroVideo from "../../assets/OL_login_spalsh.mp4";
import {
  Footer,
  Header,
  LoginSpinner,
  Modal,
  Navbar,
  ScrollToTop,
  SessionRevoker,
  UnAuthorizedModal
} from "../../components";

import TutorialBox from "../../components/TutorialBox/TutorialBox.component";
import {
  AUTH_MODE,
  NAVBAR_ITEMS
} from "../../consts";
import {
  useCommunityTrackPathname,
  useCommunityTrackVisibility,
  useContactsWidget,
  useWindowSize
} from "../../hooks";
import { useGetLanguages, useGetTranslations } from "../../queries";
import {
  useAuthStore,
  useLanguageStore,
  useNavbarStore
} from "../../stores";
import {
  addTimeToCurrentDate,
  getCookieByName,
  getEnvironmentVariables,
  getLastPath,
  throttle
} from "../../utils/general";

/// cfr. https://reactrouter.com/en/main/hooks/use-outlet-context
export type OutletContext = {
  sectionUrl: string,
  showTutorialWidget: boolean,
  setShowTutorialWidget: (item: boolean) => void
}

const { environment, analytics, favicon } = getEnvironmentVariables();

interface UserMNG {
  id: number,
  name: string,
  self_signup_enabled: boolean,
  splash: boolean,
  tutorial: boolean,
  tutorial_forced: boolean,
  maintenance_page_global: boolean
}

export function Root() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    pathname,
    state: routerState
  } = useLocation();

  const [showTutorialWidget, setShowTutorialWidget] = useState(false);
  const [showTutorialUserMenu, setShowTutorialUserMenu] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  const spinnerLoginVisible = useAuthStore(state => state.spinnerLoginVisible);
  const agreedToConditions = useAuthStore(state => Boolean(state.userInfo?.privacy_consent_flag));
  const authError = useAuthStore(state => state.error);
  const authNotice = useAuthStore(state => state.notice);
  const corporateHasOnBoarding = useAuthStore(state => Boolean(state.corporateInfo?.onboarding));
  const completedOnBoarding = useAuthStore(state => Boolean(state.userInfo?.onboarding_flag));
  const isAuthenticated = useAuthStore(state => Boolean(state.userData && state.userInfo));
  const isUnauthorized = useAuthStore(state => state.isUnAuthorized);
  // const isMoreAuthenticated = useAuthStore(state => Boolean(state.userData?.initiative_id && state.userInfo));
  const hasInitiative = useAuthStore(state => Boolean(state.userData?.initiative_id));
  const navigateTo = useAuthStore(state => state.navigateTo);
  const maintenanceGlobal = useAuthStore(state => state.maintenanceGlobal);
  const userRoles = useAuthStore(state => state.userData?.roles);
  const resetError = useAuthStore(state => state.resetError);
  const resetNavigate = useAuthStore(state => state.resetNavigate);
  const resetNotice = useAuthStore(state => state.resetNotice);
  const seedLogout = useAuthStore(state => state.seedLogout);
  const seedSession = useAuthStore(state => state.seedSession);
  const setSessionSeeding = useAuthStore(state => state.setSessionSeeding);
  const sessionToken = useAuthStore(state => state.sessionToken);
  const switchToInitiative = useAuthStore(state => state.switchToInitiative);
  const smartConfiguration = useAuthStore(state => state.smartConfiguration);
  const resetSmartConfigurator = useAuthStore(state => state.resetSmartConfigurator);
  const navbarItem = useNavbarStore(state => state.item);
  const setNavbarItem = useNavbarStore(state => state.setNavbarItem);
  const sectionUrl = (navbarItem === NAVBAR_ITEMS.FOR_YOU) ? "per-te" : "esplora";
  const isPO = userRoles?.includes("PRODUCT_OWNER");
  const qrCodeValidate = useAuthStore(state=>state.qrCodeValidate);
  const resetQrValidation = useAuthStore(state=> state.resetQrValidation);
  const { language, languageCookie, setLanguage } = useLanguageStore(state => state);

  // const splash =  useNavbarStore(state => state.splash);
  // const setSplash = useNavbarStore(state => state.setSplash);

  useCommunityTrackPathname();
  useCommunityTrackVisibility();
  useContactsWidget(hasInitiative && smartConfiguration?.freshDesk == true, language?.id || null);
  // eslint-disable-next-line max-len
  const inititiveUrl = smartConfiguration?.urlDomain ? `${smartConfiguration?.urlDomain}.${smartConfiguration.urlCorporateDomain}` : `${smartConfiguration?.urlCorporateDomain}`;
  const { width: windowWidth } = useWindowSize();

  const excessWidth = (windowWidth - 1440) + 22; // 22 is a margin value set by the widget script

  const authMode = searchParams.get("mode") as keyof typeof AUTH_MODE;
  // query param for redirect interaction with admin
  const redirectToTarget = searchParams.get("redirect_to") ?? localStorage.getItem("redirect_to");
  // eslint-disable-next-line max-len
  const redirectTo = redirectToTarget ? `${redirectToTarget}?sessionId=${sessionToken}mode=${authMode}&initiativeUrl=${inititiveUrl}` : redirectToTarget;
  redirectTo && throttledRedirectTo(redirectTo);
  // query param for seeding session
  const seedSessionId = searchParams.get("seed_session");

  // query params for setting logout state
  const errorParams = searchParams.get("error");
  const navigateParam = searchParams.get("navigate");

  const mainPage = smartConfiguration?.visForyou === false ? "/esplora" : "/per-te";
  const mainPageNavItem = smartConfiguration?.visForyou === false ? "EXPLORE" : "FOR_YOU";

  if (seedSessionId) {
    setSessionSeeding(true);
    document.cookie =
      `loadingSpinner=true;secure; expires=${addTimeToCurrentDate(5)}; domain=.${getLastPath()}; path=/`;
  }

  const userMgmtData = useLoaderData() as UserMNG;

  // const [showSplash, setShowSplash] = useState(false);

  // useEffect(() => {
  //   if (!userMgmtData?.splash) return;
  //   if(!routerState?.maintenancePage && !splash) {
  //     setShowSplash(!!userMgmtData?.splash && (mainPage === pathname));
  //     setTimeout(() => {
  //       setShowSplash(false);
  //       setSplash(true);
  //     }, 4000);
  //   }
  // }, [userMgmtData?.splash, smartConfiguration?.urlDomain, mainPage]);

  // TODO: QUESTO VA NEL LOADER!!!!
  useEffect(() => {
    if (!isPO && maintenanceGlobal) {
      navigate("/maintenance-page", { state: { isGlobalMaintenance: true, corporate: true } });
    } else {
      if(hasInitiative && smartConfiguration?.showMaintenancePage) {
        if(routerState?.maintenancePage) {
          resetSmartConfigurator();
        } else {
          navigate("/maintenance-page", { state: { isGlobalMaintenance: false } });
        }
      } else {
        /* redirect to initiative subdomain if set in smart configuration (disabled for env local) */
        if (
          (smartConfiguration?.urlCorporateDomain && smartConfiguration.urlCorporateDomain)
          && (`https://${smartConfiguration.urlDomain}.${smartConfiguration.urlCorporateDomain}` !== window.location.origin)
          && (environment !== "LOCAL")
        ) {
          setSpinnerLoginVisible(true);
          switchToInitiative();
          /* let error modal be shown in login screen */
        } else if (authError && pathname === "/access/login") {
          return;
          /* handle auth store redirect (the auth store can't use react-router hooks...) */
        } else if (navigateTo && pathname === navigateTo.target) {
          resetNavigate();
        } else if (navigateTo && pathname !== navigateTo.target) {
          navigate(navigateTo.target, { state: navigateTo.state });
          setNavbarItem(mainPageNavItem);
          /* recover auth state after redirect to initiative sub-domain */
        } else if (
          seedSessionId &&
          authMode
        ) {
          setSpinnerLoginVisible(true);
          searchParams.delete("mode");
          searchParams.delete("seed_session");
          searchParams.delete("switchToInitiative");
          setSearchParams(searchParams);
          seedSession(authMode, seedSessionId);
        } else if (errorParams && authError === null) {
          seedLogout(errorParams, null);
          navigate("/access/login");
        } else if (navigateParam) {
          searchParams.delete("navigate");
          setSearchParams(searchParams);
          navigate(navigateParam);
          /* go to login */
        } else if (
          !isAuthenticated &&
          pathname !== "/access/login" && pathname !== "/access/password/recovery" && pathname !== "/register" &&
          !routerState?.recover === true && !routerState?.firstAccess === true
        ) {
          navigate("/access/login");
          /* go to admin */
        } else if (isAuthenticated && Boolean(redirectTo)) {
          localStorage.removeItem("redirect_to");
          window.location.href = `${redirectTo}`;
          /* go to conditions agreement */
        } else if (
          !agreedToConditions && isAuthenticated &&
          pathname !== "/agreements" && pathname !== "/access/login"// && pathname !== "/"
        ) {
          setSpinnerLoginVisible(false);
          navigate("/agreements", { state: { first: true, isAuthenticated } });
        } else if (
          corporateHasOnBoarding && !completedOnBoarding
          && isAuthenticated && agreedToConditions
          && pathname !== "/access/login"
          && pathname !== "/signup"
          && pathname !== "/signup/profession"
          && pathname !== "/signup/job"
          && pathname !== "/signup/skills"
          && pathname !== "/signup/capabilities"
          && pathname !== "/signup/preferences"
          && pathname !== "/signup/summary"
        ) {
          setSpinnerLoginVisible(false);
          navigate("/signup", { state: { isAuthenticated } });
          /* go to initiative selection */
        } else if (
          isAuthenticated && !hasInitiative && agreedToConditions
          && (completedOnBoarding || !corporateHasOnBoarding)
          && !routerState?.firstAccess && pathname !== "/access/portal"
        ) {
          setSpinnerLoginVisible(true);
          navigate("/access/portal", { state: { first: true } });
          /* go to homepage */
        } else if
        (
          isAuthenticated && agreedToConditions && hasInitiative &&
          !routerState?.firstAccess && pathname === "/"
        ) {
          setNavbarItem(mainPageNavItem);
          navigate(mainPage);
        } else if (!isAuthenticated && pathname === "/register") {
          setSpinnerLoginVisible(false);
        }
      }
    }
  });


  const showMainLayout = isAuthenticated && hasInitiative
    && !pathname.includes("access")
    && !pathname.includes("signup") &&
    !seedSessionId;

  /** lingua **/
  const organizationId = useAuthStore(state => state.userData?.organization_id);
  const initiativeId = useAuthStore(state => state.userData?.initiative_id);


  const { data: multiLanguageData } = useGetLanguages({ showMainLayout, initiativeId, organizationId });
  const languages = multiLanguageData?.languages;
  const isMultilanguage = multiLanguageData?.enable_multilanguage;

  const regex = new RegExp("(^| )activeLangCode=([^;]+)");
  const match = document.cookie.match(regex);
  const cookieLang = match && match[2];

  let langObj = null;
  if (cookieLang && multiLanguageData?.languages?.some(lang => lang.id === cookieLang)) {
    langObj = multiLanguageData?.languages.filter(lang => lang.id === cookieLang)[0];
  } else if (languageCookie && multiLanguageData?.languages?.some(lang => lang.id === languageCookie.id)) {
    langObj = languageCookie;
  } else if (multiLanguageData?.primary_language){
    langObj = multiLanguageData?.primary_language;
  }
  useGetTranslations({ language: langObj, setLanguage: setLanguage });

  /** lingua **/



  (authError || authNotice) && console.warn("Root.page", { authError, authNotice });

  const tutorialCompleted = getCookieByName("tutorial-completed");
  const tutorialForcedCompleted = getCookieByName(`tutorial-completed-${sessionToken}`);

  const RenderTutorial = useMemo(() => {
    if (showMainLayout) {
      const tutorialRenderElement = () => {
        if (userMgmtData.tutorial_forced) {
          if (tutorialForcedCompleted) {
            return;
          }
          return <TutorialBox key={ `lang${language?.id}` }
            onMobileShowWidget={ (e: boolean) => setShowTutorialWidget(e) }
            onMobileShowUserMenu={ (e: boolean) => setShowTutorialUserMenu(e) }
            sessionToken={ sessionToken }
          />;
        } else {
          if (tutorialCompleted) {
            return;
          }
          if (userMgmtData.tutorial) {
            return <TutorialBox key={ `lang${language?.id}` }
              onMobileShowWidget={ (e: boolean) => setShowTutorialWidget(e) }
              onMobileShowUserMenu={ (e: boolean) => setShowTutorialUserMenu(e) }
            />;
          }
        }
      };

      return <>
        { tutorialRenderElement() }
        <Navbar />
      </>;
    } else {
      return <></>;
    }
  }, [showMainLayout, tutorialCompleted, userMgmtData.tutorial, userMgmtData.tutorial_forced, language]);

  // if (showSplash) {
  //   return <Box sx={ {
  //     background: "#000",
  //     inset: "0 0 0 0",
  //     position: "fixed",
  //     zIndex: 9999
  //   } }>
  //     <video style={ {
  //       height: "100%",
  //       width: "100%"
  //     } } src={ IntroVideo } autoPlay={ true } muted={ true } />
  //   </Box>;
  // }

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        {
          environment !== "PROD" &&
            <meta name="robots" content="noindex" />
        }
        <title>{ t("app_name") }</title>
        <link rel="icon" type="image/png" href={ `${favicon}` } />
        <style type="text/css">{ `
          /*div#freshworks-container iframe#launcher-frame {
            max-width: 1440px !important;
            ${(excessWidth > 0) ? `padding: 0 ${Math.floor(excessWidth / 2)}px;` : ""}
            width: 100%;
          }*/

          div#freshworks-container iframe#launcher-frame {
            bottom: 22px !important;
          }
        ` }
        </style>
        <script type="text/javascript">{ `${analytics}` }</script>
      </Helmet>
      <div className={ "mask" }></div>
      <Stack minHeight="100vh">
        <ScrollToTop />
        {
          // eslint-disable-next-line no-constant-condition
          false ? <SessionRevoker /> : false
        }
        <Header authenticated={ showMainLayout } showTutorialUserMenu={ showTutorialUserMenu } isMultilanguage={ isMultilanguage } languages={ languages } />
        { RenderTutorial }
        <Outlet
          context={ {
            sectionUrl,
            setShowTutorialWidget,
            showTutorialWidget
          } satisfies OutletContext }
        />

        {
          <Footer isPreLogin={ pathname.includes("access") || pathname.includes("register") } />
        }
      </Stack>
      <LoginSpinner open={ spinnerLoginVisible } />
      <UnAuthorizedModal open={ isUnauthorized } />
      <Modal
        description={ authError?.message ?? "Errore generico" }
        onClose={ () => {
          searchParams.delete("error");
          setSearchParams(searchParams);
          resetError();
        } }
        open={ Boolean(authError) }
        title="Errore"
      />
      <Modal
        description={ "Operazione avvenuta con successo" }
        onClose={ resetQrValidation }
        open={ qrCodeValidate }
        //TODO: DA TRADURRE ?
        title="Smart tv login"
      />
      <Modal
        description={ authNotice ? t(authNotice) : "" }
        onClose={ resetNotice }
        open={ Boolean(!authError && authNotice) }
        title="Attenzione"
      />
    </Fragment>
  );
}

const throttledRedirectTo =
  throttle((redirectTo: string) => {
    redirectTo && localStorage.setItem("redirect_to", redirectTo);
  }, 2000);
