import { Box, LinearProgress, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AdaptiveQuizChatSidebarBox } from "./AdaptiveQuizChat.styles";
import { Icon } from "../Icon";

interface AdaptiveQuizChatSidebarProps {
  topics: string[],
  currentIndex: number,
  progress: number,
}

const AdaptiveQuizChatSidebar = ({ topics, currentIndex, progress }: AdaptiveQuizChatSidebarProps) => {

  const theme = useTheme();

  return (
    <AdaptiveQuizChatSidebarBox gap={ 3 }>
      <Box sx={ {
        display: "flex",
        flexDirection: "column"
      } } gap={ "20px" }>
        <Typography sx={ { fontWeight: "bold" } }>Artificial Intelligence</Typography>
        <Box sx={ {
          display: "flex",
          flexDirection: "column"
        } } gap={ "20px" }>
          { topics.map((topic, index) => {
            return (
              <Box key={ `topic_${index}` } sx={ {
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              } } gap={ "20px" }>
                <Icon
                  color={ index <= currentIndex ? theme.customColors.accentVariantE : theme.customColors.textDisabled }
                  icon={ "Icons_modulo-completato" }
                  size="1.5rem"
                  sx = { { flexShrink: 0 } }
                />
                <Typography sx={ {
                  color: index <= currentIndex ? theme.customColors.textPrimary : theme.customColors.textDisabled,
                  fontSize: "14px"
                } }>{ topic }</Typography>
              </Box>
            );
          }) }
        </Box>
      </Box>

      <Box sx={ {
        display: "flex",
        flexDirection: "column",
        marginTop: 5
      } } gap={ "20px" }>
        <Typography sx={ { fontSize: "14px" } }>{ `Avanzamento ${progress || 0}%` }</Typography>
        <LinearProgress variant={ "determinate" } value={ progress } sx={ {
          backgroundColor: theme.customColors.inputBorder,
          "& .MuiLinearProgress-bar": {
            backgroundColor: theme.customColors.systemSecondary01
          }
        } } />
      </Box>
    </AdaptiveQuizChatSidebarBox>
  );
};

export default AdaptiveQuizChatSidebar;
