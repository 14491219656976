import { useGSAP } from "@gsap/react";
import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { gsap } from "gsap";
import React, { useRef } from "react";
import { ChatBubble, ChatMessage, StyledAvatar } from "./AdaptiveQuizChat.styles";
import avatarIcon from "../../assets/avatar.svg";
import { useGetProfileImageQuery } from "../../queries";
import { useAuthStore } from "../../stores";
import { stringAvatar, getUserAvatar } from "../../utils/avatar";
import DotsLoader from "../DotsLoader/DotsLoader.component";
import { Icon } from "../Icon";

interface AdaptiveQuizChatBubbleComponentProps {
  message?: string;
  sender?: string;
  highlight?: boolean;
  isError?: boolean;
  errorHandler?: ()=> void;
}

const AdaptiveQuizChatBubbleComponent = ({ message, sender, highlight, isError, errorHandler }: AdaptiveQuizChatBubbleComponentProps) => {

  const isAI = (sender === "ai");
  const theme = useTheme();
  const messageRef = useRef<HTMLDivElement>(null);

  const userData = useAuthStore(state => state.userData);
  const { data: profileImage  } = useGetProfileImageQuery({});
  const userAvatar = getUserAvatar(profileImage, userData);

  const chatBubbleRef = useRef<HTMLDivElement>(null);

  useGSAP(()=>{
    gsap.from(chatBubbleRef.current, { opacity: 0, duration: 1 });
  },[]);

  return (
    <ChatBubble key={ message } ref={ chatBubbleRef } className={ `${isAI ? "ai-message" : "user-message"}` } sx={ { opacity: highlight ? 1 : 0.6 } }>
      <Box style={ { position: "relative",
        width: "40px", height: "40px", borderRadius: 999, flexShrink: 0, border: `1px solid ${isAI ? theme.customColors.systemSecondary01 : theme.customColors.systemPrimary02}`
      } }>
        <StyledAvatar
          alt={ userAvatar.initials }
          color={ isAI ? theme.customColors.systemSecondary01 : theme.customColors.systemPrimary02 }
          { ...(isAI ? {} : stringAvatar(userAvatar.initials)) }
          src={ isAI ? avatarIcon : userAvatar.src }
        />
      </Box>
      <ChatMessage $isAi={ isAI }>
        { message ? <Typography ref={ messageRef } sx={ {
          fontSize: "14px",
          color: isError ? theme.customColors.textError : undefined,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          animation: "blink 0.5s steps(2, start) infinite",
          "@keyframes blink": {
            "50%": { borderRightColor: "transparent" }
          }
        } }>{ isError && <Icon icon={ "info" } size={ 16 }/> } { message }</Typography> : <DotsLoader /> }
      </ChatMessage>
      { isError && <Button
        onClick={ errorHandler }>
        <Icon
          color={ theme.customColors.textError }
          icon={ "Icons_reset" }
          size="1.2rem"
          sx = { { flexShrink: 0 } }
        />
      </Button> }

    </ChatBubble>
  );
};

AdaptiveQuizChatBubbleComponent.displayName = "AdaptiveQuizChatBubbleComponent";

export default AdaptiveQuizChatBubbleComponent;
