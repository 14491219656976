import {
  Stack
} from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import {
  useContext,
  useEffect, useState
} from "react";
import { Navigate } from "react-router-dom";
import { GridMain, GuardedLink, Icon } from "../../components";
import AdaptiveQuizChat from "../../components/AdaptiveQuiz/AdaptiveQuizChat.component";
import AdaptiveQuizWelcomeComponent from "../../components/AdaptiveQuiz/AdaptiveQuizWelcome.component";
import { PERMISSIONS } from "../../consts";
import { UserNavigationContext } from "../../context/UserNavigationContext";
import { useAuthStore } from "../../stores";
import { getEnvironmentVariables } from "../../utils/general";

enum Page {
  WELCOME,
  CHAT,
}

export function AdaptiveQuizPage() {
  const userData = useAuthStore(state => state.userData);
  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  const isAdmin = userData?.permissions?.some(permission => (
    permission === PERMISSIONS["admin.dashboard.access"]
  ));
  if(isAdmin){
    setSpinnerLoginVisible(false);
  }
  const { adaptiveQuizAiUri } = getEnvironmentVariables();

  const [activePage, setActivePage] = useState<Page>(Page.WELCOME);
  const theme = useTheme();

  // const { t } = useTranslation();
  const { setNavigation } = useContext(UserNavigationContext);

  useEffect(() => {
    setNavigation && setNavigation([]);
    setNavigation && setNavigation(["/"]);
  }, [location.pathname]);

  return (
    isAdmin && adaptiveQuizAiUri ?
      <GridMain sx = {
        { background: `linear-gradient(45deg, rgba(0,0,0,0) 0%,
          ${alpha(theme.customColors.backgroundSecondary,0.05)} 100%)` }
      }>
        <Stack
          gap={ 2 }
          maxWidth="calc(100vw - (100vw - 100%))"
          minWidth={ "100%" }
        >
          <GuardedLink
            alignSelf="flex-start"
            color="info"
            href={ "/esplora" }
            startIcon={
              <Icon
                icon="arrow_left"
                size={ 20 }
              />
            }
            variant="text"
            sx={ {
              fontSize: ("1.125rem"),
              fontWeight: 400,
              justifyContent: "flex-start",
              lineHeight: (11 / 9),
              paddingTop: theme.spacing(4),
              paddingX: 0
            } }
          >
            { "Torna all'esplora" }
          </GuardedLink>

          { activePage === Page.WELCOME && <AdaptiveQuizWelcomeComponent goToChat={ () => setActivePage(Page.CHAT) }/> }
          { activePage === Page.CHAT && <AdaptiveQuizChat/> }
        </Stack>
      </GridMain> :
      <Navigate to={ "/" }/>
  );
}
