import { Paper, Box, BoxProps, Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Icon } from "../Icon";

export const ContentContainer = styled(Paper)((/*{theme}*/) => ({
  //background: theme.customColors.drawerWidgetBg,
  background: "transparent",
  borderRadius: "0px",
  boxShadow: "none",
  height: "auto",
  paddingBottom:"5rem",
  width: "100%"
}));

export const WidgetContainer = styled(Paper)(({ theme }) => ({
  //background: theme.customColors.drawerWidgetBg,
  background: "transparent",
  boxShadow: "none",
  height: "auto",
  maxWidth:"350px",
  minWidth: "314px",
  overflowY:"auto",
  paddingRight:"42px",

  display:"flex",
  flexDirection:"column",
  gap:theme.spacing(2),

  [theme.breakpoints.down("sm")]:{
    gap:theme.spacing(3),
    maxWidth:"100%",
    minWidth:"100%",
    padding:"0"
  }
}));

export const StyledDrawerIcon = styled(Icon)(({ theme }) => ({
  "&":
    { "path":
      { fill: theme.customColors.systemSecondary05 }
    }
}));

const StyledOpenDrawerBox = styled(Box)<
  BoxProps & { accent: string }
>(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.customColors.backgroundPrimaryCta,
  borderRadius: "0.25rem 0 0 0.25rem",
  color: theme.palette.common?.black,
  display: "flex",
  height: "3rem",
  justifyContent: "center",
  opacity:0,
  transition:"none",
  width: "3rem",

  "&.visible":{
    opacity:1,
    transition:"opacity .3s linear",

    [theme.breakpoints.down("sm")]:{
      height: "2.5rem",
      width: "2.5rem"
    }
  }
}));

export const StyledOpenDrawerButton = styled(Button)<
  ButtonProps & { $top?: string }>(({ theme, $top }) => ({
    boxShadow: "10px 10px 30px rgba(0, 0, 0, 0.7)",
    minWidth: "3rem",
    padding: "0rem",
    position: "fixed",
    right:0,
    top: $top || "14rem",
    width: "3rem",
    zIndex: "1200",

    [theme.breakpoints.down("sm")]:{
      minWidth: "2.5rem",
      top: $top || "200px",
      width: "2.5rem"
    }
  }));

export const OpenDrawerButton = ({
  accent,
  onClick,
  icon,
  top,
  ...props
} : Omit<BoxProps, "children">
  & Pick<ButtonProps, "onClick">
  & {
    accent: string
    icon: string
    top?: string
  }
) => (
  <StyledOpenDrawerButton onClick={ onClick } title={ "sidebar" } $top={ top }>
    <StyledOpenDrawerBox accent={ accent } { ...props }>
      <StyledDrawerIcon icon={ icon } size={ 19 }/>
    </StyledOpenDrawerBox>
  </StyledOpenDrawerButton>
);

export const StyledCloseDrawerBox = styled(Box)<
  BoxProps & { accent: string }
>(({ accent, theme }) => ({
  alignItems: "center",
  backgroundColor: accent,
  borderRadius: "0 0.25rem 0.25rem 0",
  color: theme.palette.common?.black,
  display: "flex",
  height: "3rem",
  justifyContent: "center",
  opacity:0,
  width: "3rem",
  [theme.breakpoints.down("sm")]:{
    height: "2.5rem",
    width: "2.5rem"
  },

  "&.visible":{
    opacity:1,
    transition:"opacity .3s linear"
  }
}));

export const StyledCloseDrawerButton = styled(Button)<
  ButtonProps & { $top?: string }>(({ theme, $top }) => ({
    boxShadow: "10px 10px 30px rgba(0, 0, 0, 0.7)",
    left:0,
    minWidth: "2.5rem",
    padding: "0rem",
    position: "fixed",
    top: $top || "14rem",
    width: "3rem",
    zIndex: "1200",

    [theme.breakpoints.down("sm")]:{
      top: $top || "200px",
      width: "2.5rem"
    }

  }));

export const CloseDrawerButton = ({
  accent,
  icon,
  top,
  onClick,
  ...props
} : Omit<BoxProps, "children">
  & Pick<ButtonProps, "onClick">
  & { accent: string, icon: string, top?: string }
) => (
  <StyledCloseDrawerButton onClick={ onClick } $top={ top }>
    <StyledCloseDrawerBox accent={ accent } { ...props }>
      <StyledDrawerIcon icon={ icon } size={ 19 }/>
    </StyledCloseDrawerBox>
  </StyledCloseDrawerButton>
);
