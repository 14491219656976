import { useTheme } from "@mui/material/styles";
import { millisecondsToSeconds } from "date-fns/esm/fp";
import { t } from "i18next";
import {
  type MutableRefObject,
  RefObject,
  useEffect,
  useMemo,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import {
  CONTENT_TYPE,
  ENROLL_TYPE,
  LEVEL,
  NAVBAR_ITEMS,
  STATUS_LO
} from "./consts";
import { usePostCommunityTracking } from "./queries/community";
import {
  useAuthStore,
  useComunityStore,
  useNavbarStore
} from "./stores";
import type {
  ContentType,
  LearningObject,
  Level
} from "./types";
import { addEscapeString } from "./utils/general";

export function useAccent({
  isExpired,
  isMandatory,
  isSuggestedAI,
  isSuggestedHR,
  isSuggestedManager,
  status
} :
  Partial<
    Pick<
      LearningObject,
      | "isExpired"
      | "isSuggestedAI"
      | "isSuggestedHR"
      | "isSuggestedManager"
      | "isMandatory"
      | "status"
    >
  >
) {
  const theme = useTheme();

  const accentColor = useMemo(() => (
    isExpired && isMandatory &&
    (status === "E" || status === "P" )
      ? theme.customColors.accentVariantC
      : status === STATUS_LO.C || isSuggestedAI || isSuggestedHR || isSuggestedManager
        ? theme.customColors.accentVariantB
        : theme.customColors.accentVariantA
  ), [
    isExpired,
    isMandatory,
    isSuggestedAI,
    isSuggestedHR,
    isSuggestedManager,
    status,
    theme.customColors.accentVariantA,
    theme.customColors.accentVariantB,
    theme.customColors.accentVariantC
  ]);

  return accentColor;
}

export function useContentsMap({
  booked = false,
  learningObjectTypology,
  status
} :
  & Partial<
    Pick<
      LearningObject,
      | "learningObjectTypology"
      | "status"
    >
  & { booked: boolean }
  >
) {
  const contentsMap = {
    ASYNC: {
      info: CONTENT_TYPE.ASYNC,
      label: getCtaLabel({ booked, status }),
      learningObjectTypology: `${useLevel(learningObjectTypology)}`
    },
    BLENDED: {
      info: CONTENT_TYPE.BLENDED,
      label: getCtaLabel({ booked, status }),
      learningObjectTypology: `${useLevel(learningObjectTypology)}`
    },
    SYNC: {
      info: CONTENT_TYPE.SYNC,
      label: booked ? "detail" : "register",
      learningObjectTypology: `${useLevel(learningObjectTypology)}`
    }
  } as const;
  return contentsMap;
}

type ButtonStatusProps =
  & Partial<
    Pick<
      LearningObject,
      | "ecm_specialization"
      | "ecmRegistration"
      | "learningObjectType"
      | "learningObjectTypology"
      | "percentageOfCompletion"
      | "enrollType"
    >
  > & {
    isCard?: boolean
    isSurvey?:boolean
    isTest?:boolean
    madeAttempts?:number
  }

export function useButtonStatusMap({
  ecm_specialization,
  ecmRegistration,
  enrollType,
  isCard=true,
  isSurvey=false,
  isTest=false,
  learningObjectType,
  learningObjectTypology,
  madeAttempts=0,
  percentageOfCompletion="0%"
} : ButtonStatusProps) {
  const buttonMap = ({
    C: {
      label:isCompletedText({
        isCard,
        isSurvey,
        isTest,
        learningObjectType,
        learningObjectTypology,
        percentageOfCompletion })
    }, //COMPLETED
    E: {
      label: isEnrolledText({
        ecm_specialization,
        ecmRegistration,
        isCard, isSurvey, isTest, learningObjectType, learningObjectTypology , madeAttempts })
    }, //ENROLLED
    N: {
      label: notIsEnrolledTex({
        ecm_specialization,
        ecmRegistration,
        enrollType,
        isCard,
        isSurvey,
        isTest,
        learningObjectType,
        learningObjectTypology })
    }, // NOT ENROLLED
    P: {
      label: isProgressText({
        isSurvey:isSurvey,
        isTest:isTest,
        learningObjectTypology:learningObjectTypology
      })
    }, //PROGRESS
    R: {
      label: isCard ? "details" : "register_request"
    }, //REFUSED
    T: {
      label: notTpIsEnrolledTex({ isCard, isSurvey, enrollType, isTest,learningObjectType, learningObjectTypology })
    },
    W: {
      label: isCard ? "details" : "validation_text"
    } //WAITING APPROVATION
  } as const);

  return buttonMap;
}

const isProgressText=({
  isSurvey,
  isTest,
  learningObjectTypology
} : ButtonStatusProps) => {
  if(isTest || isSurvey){
    return "complete";
  }
  else if(learningObjectTypology === LEVEL.DA_FINAL_BALANCE ){
    return  "balance_view";
  }
  else if (learningObjectTypology === LEVEL.DA_GOALS){
    return  "goals_view";
  }
  else if (learningObjectTypology === LEVEL.DA_MATERIAL){
    return  "material_view";
  }
  else if (learningObjectTypology === LEVEL.DA_MEETING){
    return  "details" ;
  }
  else {
    return "resume";
  }
};
const notIsEnrolledTex = ({
  ecmRegistration,
  ecm_specialization,
  enrollType,
  isCard=false,
  isSurvey,
  isTest,
  learningObjectType,
  learningObjectTypology
} : ButtonStatusProps) => {
  const digital =
    learningObjectType === CONTENT_TYPE.ASYNC
    || learningObjectType === CONTENT_TYPE.BLENDED;

  if(isSurvey || isTest){
    return "complete";
  }
  if(ecm_specialization){
    if(!ecmRegistration){
      if(isCard){
        return "details";
      }
      else {
        return "register";
      }
    }
    else {
      return "start";
    }

  }
  //label LO NotEnrolled Card Case:
  if (isCard) {
    // digital LO or Blended (a Blended type could include a digital one)
    if (digital && enrollType === ENROLL_TYPE.AUTO_ENROLL && (
      learningObjectTypology !== LEVEL.DA_GOALS
      && learningObjectTypology !== LEVEL.DA_MEETING
      && learningObjectTypology !== LEVEL.DA_FINAL_BALANCE
      && learningObjectTypology !== LEVEL.DA_MATERIAL
    )) {
      return "start";
    } else {
      return "details";
    }
  } else if (learningObjectType === CONTENT_TYPE.SYNC) {
    if (
      learningObjectTypology === LEVEL.DA_PHYSICAL_CLASS
      || learningObjectTypology === LEVEL.DA_VIRTUAL_CLASS
    ) {
      if (enrollType === ENROLL_TYPE.REQUESTED_AUTO_ENROLL) {
        return "register_request";
      } else {
        return "editions_show";
      }
    } else if (learningObjectTypology === LEVEL.COURSE) {
      if (enrollType === ENROLL_TYPE.REQUESTED_AUTO_ENROLL) {
        return "register_request";
      } else {
        return "activities_show";
      }
    }
    else if (learningObjectTypology === LEVEL.DA_MEETING){
      return "details";
    }
    else {
      return "modules_show";
    }
  } else if (digital && enrollType === ENROLL_TYPE.AUTO_ENROLL) {
    return "start";
  }
  else if(learningObjectTypology === LEVEL.DA_FINAL_BALANCE ){
    return  "balance_view";
  }
  else if (learningObjectTypology === LEVEL.DA_GOALS){
    return  "goals_view";
  }
  else if (learningObjectTypology === LEVEL.DA_MATERIAL){
    return  "material_view";
  }
  else if (learningObjectTypology === LEVEL.DA_MEETING){
    return  "details" ;
  }
  else {
    return "register_request";
  }
};

const notTpIsEnrolledTex = ({
  isCard,
  isSurvey,
  isTest,
  enrollType,
  learningObjectType,
  learningObjectTypology
} : ButtonStatusProps) => {

  if(isSurvey || isTest){
    return "complete";
  }
  else if (isCard) {
    return "details";
  } else if (learningObjectType === CONTENT_TYPE.SYNC) {
    if (
      learningObjectTypology === LEVEL.DA_PHYSICAL_CLASS
      || learningObjectTypology === LEVEL.DA_VIRTUAL_CLASS
    ) {
      if (enrollType === ENROLL_TYPE.REQUESTED_AUTO_ENROLL) {
        return "register_request";
      } else {
        return "editions_show";
      }
    } else if (learningObjectTypology === LEVEL.COURSE) {
      if (enrollType === ENROLL_TYPE.REQUESTED_AUTO_ENROLL) {
        return "register_request";
      } else {
        return "activities_show";
      }
    } else {
      return "modules_show";
    }
  } else {
    return "register_request";
  }
};

const isEnrolledText = ({
  ecm_specialization,
  ecmRegistration,
  isCard,
  isSurvey,
  isTest,
  learningObjectType,
  learningObjectTypology,
  madeAttempts
} : ButtonStatusProps) => {
  //label LO Enrolled All contents LIVE
  if(isSurvey || isTest){
    if(madeAttempts){
      return "retry";
    }
    else {
      return "complete";
    }
  }
  if(ecm_specialization){
    if(!ecmRegistration){
      if(isCard){
        return "details";
      }
      else {
        return "register";
      }
    }
    else {
      return "start";
    }

  }
  else if (learningObjectType === CONTENT_TYPE.SYNC) {
    if (isCard) {
      //All Cards case:
      return "details";
    } else if (learningObjectTypology === LEVEL.DA_PHYSICAL_CLASS) {
      // HeroBanner case live event fissare cta action su path
      // http://localhost:5173/esplora/dettaglio/348/DA_PHYSICAL_CLASS
      return "editions_show";
    } else if (learningObjectTypology === LEVEL.DA_VIRTUAL_CLASS) {
      return "join";
    } else if (learningObjectTypology === LEVEL.COURSE) {
      // HeroBanner case virtual
      return "activities_show";
    } else {
      return "modules_show";
    }
  }
  else {
    if(learningObjectTypology === LEVEL.DA_FINAL_BALANCE ){
      return  "balance_view";
    }
    else if (learningObjectTypology === LEVEL.DA_GOALS){
      return  "goals_view";
    }
    else if (learningObjectTypology === LEVEL.DA_MATERIAL){
      return  "material_view";
    }
    else if (learningObjectTypology === LEVEL.DA_URL_RES){
      return isCard ? "details" : "content_view";
    }
    else if (learningObjectTypology === LEVEL.DA_MEETING){
      return "details";
    }
    else {
      return "start";
    }

  }
};

const isCompletedText = ({
  isCard,
  isSurvey,
  isTest,
  learningObjectType,
  learningObjectTypology,
  percentageOfCompletion
} : ButtonStatusProps) => {
  if(isSurvey || isTest){
    return "completed";
  }
  //label LO Enrolled All contents LIVE
  else if (learningObjectType === CONTENT_TYPE.SYNC) {
    if (isCard) {
      //All Cards case:
      return "details";
    } else if (
      learningObjectTypology === LEVEL.DA_PHYSICAL_CLASS
      || learningObjectTypology === LEVEL.DA_VIRTUAL_CLASS
    ) {
      return "editions_show";
    }  else if (learningObjectTypology === LEVEL.COURSE) {
      // HeroBanner case virtual
      return "activities_show";
    } else {
      return "modules_show";
    }
  } else {
    if(learningObjectTypology === LEVEL.DA_FINAL_BALANCE ){
      return  "balance_view";
    }
    else if (learningObjectTypology === LEVEL.DA_GOALS){
      return  "goals_view";
    }
    else if (learningObjectTypology === LEVEL.DA_MATERIAL){
      return  "material_view";
    }
    else if (learningObjectTypology === LEVEL.DA_MEETING){
      return  "details" ;
    }
    else if (learningObjectTypology === LEVEL.DA_URL_RES){
      return isCard ? "details" : "content_view";
    }
    else {
      return percentageOfCompletion === "100%" || percentageOfCompletion === "100.0" ?  "restart" : "resume";
    }
  }
};

export function useIntersectionObserver({
  callback,
  container
} : {
  callback: () => void
  container: MutableRefObject<HTMLDivElement | null>
}) {
  const [intersectionObserver, setIntersectionObserver] = useState<IntersectionObserver | null>(null);

  useEffect(() => {
    if (container.current === null) return;
    if (intersectionObserver === null) {
      const newObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              callback();
            }
          });
        },
        {
          root: container.current
        }
      );

      setIntersectionObserver(newObserver);
    }

    return () => intersectionObserver?.disconnect();
  }, [callback, container, intersectionObserver]);

  return intersectionObserver;
}
export function useResumeLink({
  ecmRegistration,
  ecm_specialization,
  enrollType,
  id,
  labelMoreInfo=false,
  learningObjectType,
  learningObjectTypology,
  sectionUrl,
  status
} : Pick<
  LearningObject,
  | "ecm_specialization"
  | "ecmRegistration"
  | "enrollType"
  | "id"
  | "learningObjectType"
  | "learningObjectTypology"
  | "status"
> & {
  labelMoreInfo?: boolean
  sectionUrl: string
}) {
  const buttonStatusMap = useButtonStatusMap({
    ecm_specialization,
    ecmRegistration,
    enrollType,
    learningObjectType,
    learningObjectTypology
  });
  const buttonText = buttonStatusMap[status]?.label;

  if (
    !labelMoreInfo && (
      buttonText === "restart"
      || buttonText === "resume"
      || buttonText === "start"
    )
  ) {
    return `/${sectionUrl}/guarda/${id}/${learningObjectTypology}`;
  } else {
    return  `/${sectionUrl}/dettaglio/${id}/${learningObjectTypology}`;
  }
}

export const useType = (
  learningObjectType: ContentType,
  learningObjectTypology?: Level
) => {
  if (learningObjectType === CONTENT_TYPE.ASYNC) {
    return t("digital");
  } else if (learningObjectType === CONTENT_TYPE.BLENDED) {
    return t("blended");
  } else if (learningObjectTypology === LEVEL.DA_PHYSICAL_CLASS) {
    return t("live_classroom");
  } else if (learningObjectTypology === LEVEL.DA_VIRTUAL_CLASS) {
    return t("virtual_classroom");
  } else {
    return t("live");
  }
};

export const useLevel = (learningObjectTypology?: Level) => {
  const { t } = useTranslation();

  switch (learningObjectTypology) {
  case LEVEL.COURSE :
    return t("course");
  case LEVEL.DA_EXTERNAL_RES:
    return t("learning_activity");
  case LEVEL.DA_SCORM:
    return t("learning_activity");
  case LEVEL.DA_XAPI:
    return t("learning_activity");
  case LEVEL.DA_PHYSICAL_CLASS:
    return t("learning_activity");
  case LEVEL.DA_VIRTUAL_CLASS:
    return t("learning_activity");
  case LEVEL.PATH :
    return t("package");
  default:
    return t("learning_activity");
  }
};
export function useOnboardingSteps(){
  const corporateInfo = useAuthStore(state => state.corporateInfo);
  const hasProfessionStep = corporateInfo?.onboarding_capability
  || corporateInfo?.onboarding_executive_job
  || corporateInfo?.onboarding_personal_job;

  if(hasProfessionStep){
    return  ["personal_data", "profession" , "personalization",  "summary"];
  }
  else {
    return ["personal_data", "personalization",  "summary"];
  }
}

function getCtaLabel({
  booked,
  status
}:
  & Partial<
    Pick<
      LearningObject,
      | "status"
    >
  >
  & {
    booked?: boolean,
  }
) {
  if (!booked) {
    return "subscribe";
  } else if (status && status === STATUS_LO.E) {
    return "begin";
  } else if (status && status === STATUS_LO.C) {
    return "restart";
  } else {
    return "resume";
  }
}

export function useIconStatus({
  isSuggestedAI,
  isSuggestedHR,
  isSuggestedManager,
  status
} : Partial<
    Pick<
      LearningObject,
      | "status"
      | "isSuggestedAI"
      | "isSuggestedHR"
      | "isSuggestedManager"
    >
  >
) {


  const iconInfo = useMemo(() => (
    isSuggestedAI ? "AI" :
      isSuggestedHR ? "Icons_invito" :
        isSuggestedManager ? "manager_suggested" :
          status === "C" ? "completato" : "Icons_obbligatorio"
  ), [
    isSuggestedAI,
    isSuggestedHR,
    isSuggestedManager,
    status
  ]);
  return iconInfo;
}

export function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState("");

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const updateScrollDirection = () => {
      const scrollY = window.scrollY;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (direction !== scrollDirection && (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    };
  }, [scrollDirection]);

  return scrollDirection;
}

export function useElementSize(ref: RefObject<HTMLDivElement>) {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [elementSize, setElementSize] = useState<{
    height?: number,
    width?: number,
  }>({ height: undefined, width: undefined });

  useEffect(() => {
    function handleResize() {
      setElementSize({
        height: ref.current?.getBoundingClientRect()?.height,
        width: ref.current?.getBoundingClientRect()?.width
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [ref]);

  return elementSize;
}

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<{
    height: number,
    width: number,
  }>({ height: Infinity, width: Infinity });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

export const useContactsWidget = (hasInitativeFreshDesk: boolean, language: string | null) => {
  const theme = useTheme();
  const smartConfiguration = useAuthStore(state => state.smartConfiguration);

  const userInfo = useAuthStore(state => state.userInfo);
  const clearDivs = () => {
    const scriptDivs = document.querySelectorAll("#freshworks-container") as NodeListOf<HTMLDivElement>;
    const iframeDivs = document.querySelectorAll("#freshworks-frame") as NodeListOf<HTMLDivElement>;
    scriptDivs.forEach(div => div.parentNode?.removeChild(div));
    iframeDivs.forEach(div => div.parentNode?.removeChild(div));
  };

  useEffect(() => {

    const idWidgetFreshdesk = (smartConfiguration?.idWidgetFreshdesk ?? "60000004142");
    const scriptSrc = `https://widget.freshworks.com/widgets/${idWidgetFreshdesk}.js`;

    const loadFreshworksWidget = () => {
      clearDivs();
      window.fwSettings = {
        widget_id: idWidgetFreshdesk,
        locale: language || "it"
      };

      const freshworksWidgetScript = document.createElement("script");
      freshworksWidgetScript.src = scriptSrc;
      freshworksWidgetScript.async = true;
      document.body.appendChild(freshworksWidgetScript);

      const interval = setInterval(() => {
        if (window.FreshworksWidget) {
          clearInterval(interval);
          window.FreshworksWidget("identify", "ticketForm", {
            name: `${addEscapeString(userInfo?.name ?? "") + " "} ${addEscapeString(userInfo?.surname ?? "")}`,
            email: userInfo?.email ?? ""
          });
          window.FreshworksWidget("setConfig", "ticketForm", {
            backgroundColor: theme.customColors.systemPrimary02
          });
          window.FreshworksWidget("setLabels", {
            [language || "it"]: {
              "banner": t("helpdesk_banner"),
              "launcher": t("helpdesk_launcher"),
              "contact_form": {
                "title": t("helpdesk_contact_form"),
                "submit": t("helpdesk_contact_form_submit"),
                "confirmation": t("helpdesk_contact_form_confirmation")
              }
            }
          });
        }
      }, 500);
    };

    if(hasInitativeFreshDesk) {

      //Rimuovo lo script già esistente
      const existingScript = document.querySelector(`script[src="${scriptSrc}"]`);
      if (existingScript) existingScript.remove();

      setTimeout(() => loadFreshworksWidget(), 500);
    }

    return () => {
      const scriptToRemove = document.querySelector(`script[src="${scriptSrc}"]`);
      if (scriptToRemove) scriptToRemove.remove();
      if(window.FreshworksWidget) window.FreshworksWidget("close");
      clearDivs();
    };

  }, [language, userInfo?.email, userInfo?.name, userInfo?.surname]);
};

export const useIsForYou = () => {
  const item = useNavbarStore(state => state.item);
  const isForYou = useMemo(
    () => item === NAVBAR_ITEMS.FOR_YOU,
    [item]
  );
  return isForYou;
};

function useIsCommunitySection() {
  const { pathname } = useLocation();
  return pathname.split("/")[1] === "community";
}

export function useCommunityTrackPathname() {
  const comunityStoredTime = useComunityStore(state => state.time);
  const comunityStatus = useComunityStore(state => state.status);
  const setCommunityState = useComunityStore(state => state.setComunityState);
  const isCommunitySection = useIsCommunitySection();
  const postCommunityTracking = usePostCommunityTracking();

  useEffect(()=> {
    // on pathname change~~
    // if it's community section
    if (isCommunitySection) {
      // if community store is not initialized
      if (comunityStatus !== "initialized" && !comunityStoredTime) {
        // initize community store and track
        const currentTime = millisecondsToSeconds(new Date ().getTime());
        setCommunityState({ status: "initialized", time: currentTime });
        postCommunityTracking({ duration: 0, verb: "viewed" });
      }
    // if it's not community section and community store is initialized
    } else if (comunityStoredTime) {
      // track community
      const currentTime = millisecondsToSeconds(new Date ().getTime());
      const timeUpdate = currentTime - comunityStoredTime;
      setCommunityState({ status: "escaped", time:0 });
      postCommunityTracking({ duration:timeUpdate, verb:"experienced" });
    }
  }, [
    comunityStatus,
    comunityStoredTime,
    isCommunitySection,
    postCommunityTracking,
    setCommunityState
  ]);
}

export function useCommunityTrackVisibility() {
  const comunityStoredTime = useComunityStore(state => state.time);
  const isCommunitySection = useIsCommunitySection();
  const setCommunityState = useComunityStore(state => state.setComunityState);
  const postCommunityTracking = usePostCommunityTracking();

  useEffect(() => {
    // on dom visibility change~~
    const handleVisibilityChange = () => {
      // if it's community section
      if (isCommunitySection) {
        // if the user is leaving (switched tabs or left the page)
        if (document.hidden) {
          // update community store and track
          let timeUpdate = 0;
          if (comunityStoredTime) {
            const currentTime = millisecondsToSeconds(new Date ().getTime());
            timeUpdate = currentTime - comunityStoredTime;
          }
          setCommunityState({ status: "escaped", time: 0 });
          postCommunityTracking({ duration: timeUpdate, verb: "experienced" });
        // if the user is coming back to the page
        } else {
          // update community store
          const currentTime = millisecondsToSeconds(new Date().getTime());
          setCommunityState({ status: "initialized", time: currentTime });
        }
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [
    comunityStoredTime,
    isCommunitySection,
    postCommunityTracking,
    setCommunityState
  ]);
}
