import { Box, Typography, useTheme } from "@mui/material";
import { gsap } from "gsap";
import { useEffect, useRef } from "react";
import { ResultMessageBox, TitleTypography } from "./AdaptiveQuizChat.styles";
import AdaptiveQuizChatAnimatedAvatarComponent from "./AdaptiveQuizChatAnimatedAvatar.component";
import { Button } from "../Button/Button.component";
import { Icon } from "../Icon";

type AdaptiveQuizResultParams = {
  evaluation: boolean,
  result: boolean,
  quizResults?: {
    message: string,
    valutazione: {
      aree_di_miglioramento: string,
      punti_di_forza: string
    }
  },
}

const AVATAR_HEIGHT = 100;
const SLIDE_FADE_TOP= 30;
const PADDING_BOX = 10;

const AdaptiveQuizResultComponent = ({ evaluation, result, quizResults }: AdaptiveQuizResultParams)=>{

  const theme = useTheme();

  const resultRef = useRef(null);
  const avatarRef = useRef(null);
  const evaluationContainerRef = useRef(null);

  useEffect(() => {
    if (resultRef?.current && result && avatarRef?.current) {
      gsap.timeline().fromTo(avatarRef?.current, {
        ease: "power4.out",
        left: "50%",
        transform: "translate(-50%)",
        opacity: 1
      },{
        duration: 2,
        ease: "power4.out",
        left: `${PADDING_BOX}px`,
        transform: "translate(0)",
        opacity: 1
      }).fromTo(resultRef.current,
        {
          opacity: 0
        },
        {
          duration: 1,
          ease: "power1.out",
          opacity: 1,
          top: `${PADDING_BOX}px`
        }, "0.5");
    }
  }, [result, avatarRef?.current, resultRef?.current]);

  useEffect(() => {
    if (evaluationContainerRef?.current && evaluation && avatarRef?.current){
      gsap.timeline().fromTo(avatarRef?.current, {
        ease: "power4.out",
        left: "50%",
        top: `${SLIDE_FADE_TOP + PADDING_BOX}px`,
        transform: "translate(-50%)",
        opacity: 0
      },{
        duration: 2,
        ease: "power4.out",
        top: `${PADDING_BOX}px`,
        left: "50%",
        transform: "translate(-50%)",
        opacity: 1
      }, ">").to(evaluationContainerRef?.current,
        {
          duration: 2,
          ease: "power4.out",
          opacity: 1,
          top: `${PADDING_BOX}px`
        }, "+0.3");
    }
  }, [evaluation, avatarRef?.current, evaluationContainerRef?.current]);

  return <>
    <AdaptiveQuizChatAnimatedAvatarComponent loading={ !result }
      ref={ avatarRef }
      height={ AVATAR_HEIGHT }
      sx={ { position: "absolute", left: "50%", top: `${PADDING_BOX}px`, transform: "translate(-50%)" } }/>
    { evaluation && <Box ref={ evaluationContainerRef } sx={ {
      display: "flex",
      opacity: 0,
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      maxWidth: "600px",
      marginX: "auto",
      padding: `${SLIDE_FADE_TOP}px`,
      position: "relative",
      paddingTop: `${AVATAR_HEIGHT+40}px`,
      top: "30px",
      [theme.breakpoints.down("sm")]: {
        paddingX: 2
      }
    } } gap={ 3 }>
      <TitleTypography>Congratulazioni!</TitleTypography>
      <Typography sx={ { textAlign: "center" } }>
        Hai completato il quiz.<br/>
        Sto elaborando i tuoi risultati per creare un percorso di apprendimento personalizzato...
      </Typography>
    </Box> }
    { result && quizResults &&
      <Box ref={ resultRef } sx={ {
        position: "relative",
        top: "100px",
        left: 0,
        right: 0,
        opacity: 0,
        width: "100%",
        [theme.breakpoints.up("lg")]: {
          padding: `${PADDING_BOX}px 150px`
        },
        [theme.breakpoints.down("lg")]: {
          padding: `${PADDING_BOX}px 0`
        },
        margin: "0 auto"
      } }>
        <Box sx={ {
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: `${PADDING_BOX}px 0`,
          width: "100%"
        } }>
          <ResultMessageBox $isAi={ true } sx={ { paddingY: 2 } }>
            <Typography sx={ {
              fontSize: "16px",
              [theme.breakpoints.down("sm")]: {
                fontSize: "14px"
              }
            } }>{ quizResults.message }</Typography>
          </ResultMessageBox>
        </Box>
        <Box sx={ {
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          marginTop: "48px",
          marginX: "auto"
        } } gap={ "32px" }>
          <Box sx={ {
            alignItems: "stretch",
            border: "1px solid gray",
            borderRadius: "10px",
            boxShadow: "10px 10px 68.5px #000000",
            display: "flex",
            flex: 1,
            width: "100%",
            flexDirection: "column",
            padding: 5
          } } gap={ 2 }>

            <Box sx={ {
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            } } gap={ "20px" }>
              <Icon
                color={ theme.customColors.systemSecondary01 }
                icon={ "Icons_modulo-completato" }
                size="1.5rem"
                sx = { { flexShrink: 0 } }
              />
              <Typography sx={ {
                color: theme.customColors.systemSecondary01, fontWeight: "bold"
              } }>OTTIMO LAVORO!</Typography>
            </Box>

            <Typography>{ quizResults.valutazione.punti_di_forza }</Typography>
          </Box>

          <Box sx={ {
            borderRadius: "10px",
            border: "1px solid gray",
            boxShadow: "10px 10px 68.5px #000000",
            padding: 5,
            display: "flex",
            flexDirection: "column",
            flex: 1,
            alignItems: "stretch"
          } } gap={ 2 }>

            <Box sx={ {
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            } } gap={ "20px" }>
              <Icon
                color={ theme.customColors.systemPrimary02 }
                icon={ "Icons_obbligatorio" }
                size="1.5rem"
              />
              <Typography sx={ {
                color: theme.customColors.systemPrimary02, fontWeight: "bold"
              } }>SU COSA LAVOREREMO</Typography>
            </Box>
            <Typography>{ quizResults.valutazione.aree_di_miglioramento }</Typography>
          </Box>
        </Box>

        <Box sx={ {
          marginTop: "80px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          [theme.breakpoints.down("sm")]: {
            marginTop: "40px"
          }
        } }>
          <Button variant={ "contained" }>Crea il tuo percorso!</Button>
        </Box>
      </Box>
    }
  </>;
};

export default AdaptiveQuizResultComponent;
